<template>
<div>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <div class="text-h4 text-center">Create User</div>
          <v-form ref="form"
                v-model="valid"
                lazy-validation
            >
            <v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-alert type="error" v-if="addErrResp">{{addErrResp}}</v-alert>
                  <v-text-field
                    v-model="formData.name"
                    label="Name"
                    type="text"
                    required
                    :rules="rules.nameRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="3">
                  <v-text-field v-model="formData.email" label="Email" type="email" :rules="rules.emailRules"></v-text-field>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field v-model="formData.contact_number" label="Contact No" type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.password" label="Password" type="password"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.password_confirmation" label="Confirm Password" type="password"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="pb-0">Loan Management Permissions</v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.people" label="Access People" color="success" hide-details></v-switch>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.report" label="Access Reports" color="success" hide-details></v-switch>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-switch v-model="formData.transaction" label="Access Transactions" color="success" hide-details class="mt-0"></v-switch>
                </v-col>
              </v-row>
               <v-row class="justify-center">
                <v-col cols="12" lg="6" class="pb-0">Investment Management Permissions</v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.investment" label="Access Investments" color="success" hide-details></v-switch>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.return_of_investment" label="Access ROI" color="success" hide-details></v-switch>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-switch v-model="formData.project" label="Access Projects" color="success" hide-details class="mt-0"></v-switch>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="primary" class="px-10" @click="addPeople()" :disabled="addLoading">Create</v-btn><br>
                   <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="addLoading"
                    ></v-progress-circular>
                </v-col>
              </v-row>  
            </v-container>
          </v-form>
        </v-card>  
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import {create_user} from "@api/users"
export default {
  data(){
    return {
      valid:true,
      formData:{
        name:"",
        email:"",
        contact_number:"",
        password:"",
        password_confirmation:'',
        transaction:false,
        people: false,
        report: false,
        project:false,
        investment: false,
        return_of_investment:false,
      },
      rules:{
        nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      },
      addErrResp:'',
      addLoading:false,
    }
  },
  methods:{
    addPeople(){
      this.formData.people=this.formData.people===true?1:0;
      this.formData.transaction=this.formData.transaction===true?1:0;
      this.formData.report=this.formData.report===true?1:0;
      this.formData.project=this.formData.project===true?1:0;
      this.formData.investment=this.formData.investment===true?1:0;
      this.formData.return_of_investment=this.formData.return_of_investment===true?1:0;
      this.addLoading=true;
      this.addErrResp=''
      create_user(this.formData).then(resp=>{
        this.addLoading=false;
        if(resp.status==='Success'){
          this.$toastr.s(resp.message);
          this.$refs.form.reset();
          this.$router.push('/users');
        }else{
          this.addErrResp=resp.errors;
        }
      });
    }
  }
}
</script>

<style>

</style>